import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import { ContentWrapper } from '../components/content_wrapper/content_wrapper';
import { SEO } from '../components/seo';
import { GatsbyPageProps } from '../gatsby-types';
import Layout from '../layouts/layout';
import { linkResolver } from '../lib/prismic';

export const query = graphql`
  {
    prismicSignupThanksPage {
      data {
        body {
          raw
        }
      }
    }
  }
`;

const SignUpThanksPage = (props: GatsbyPageProps) => {
  const page = props.data.prismicSignupThanksPage;
  return (
    <Layout>
      <SEO title="Thanks for signing up" />
      <ContentWrapper>
        <article>
          <RichText render={page.data.body} linkResolver={linkResolver} />
        </article>
      </ContentWrapper>
    </Layout>
  );
};

export default SignUpThanksPage;
